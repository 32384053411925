import React from 'react';
// import logo from '../../assets/images/logo-hitachi-vantara.png';
// import logo1 from '../../assets/images/HDS_Logo_Digital_InspireGray.svg';
// import logo2 from '../../assets/images/HDS_Logo_Digital_White.svg';
import logo1 from '../../assets/images/hitachi-logo-black-and-white.png';
import useToken from '../../useToken';

function Logo() {
	const { token } = useToken();

	return (
		<div>
			<div style={{ display: 'flex' }}>
				{token ? (
					<a href="/dashboard">
						<img alt="logo" src={logo1} style={{ height: '50px', padding: '10px 0px' }}></img>
					</a>
				) : (
					<img alt="logo" src={logo1} style={{ height: '50px', padding: '10px 0px' }}></img>
				)}
			</div>
		</div>
	);
}

export default Logo;
